


















import {defineComponent} from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'AddToCart',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    isCartActiveBox: {
      type: Boolean,
      default: true
    },
    ifUsed: {
      type: Boolean,
      default: false
    }
  }
})
