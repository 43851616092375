<template>
  <div>
    <div class="sf-gallery config-gallery" v-show="glide !== null">
      <div class="sf-gallery__stage">
        <div ref="pg_glide" class="glide">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides" v-if="images">
              <li
                v-for="(picture, index) in images"
                :key="'slide-' + index"
                class="glide__slide prod-gallery"
              >
                <slot
                  name="big-image"
                  v-bind="{
                    picture,
                    index,
                  }"
                >
                  <a
                    data-fancybox="gallery"
                    :data-src="picture.desktop.url"
                    :data-caption="picture.alt"
                    draggable="false"
                  >
                    <nuxt-img
                      v-if="index < 1"
                      ref="sfGalleryBigImage"
                      preload
                      fetchpriority="high"
                      class="sf-gallery__big-image"
                      :src="picture.desktop.url"
                      :alt="picture.alt"
                      :placeholder="picture.placeholder"
                      @click="$emit('click:stage', { picture, index })"
                      format="webp"
                      sizes="sm:380px md:400px lg:640px"
                      quality="80"
                      width="570"
                      height="380"
                    />
                    <nuxt-img
                      v-else
                      ref="sfGalleryBigImage"
                      loading="lazy"
                      class="sf-gallery__big-image"
                      :src="picture.desktop.url"
                      :alt="picture.alt"
                      :placeholder="picture.placeholder"
                      @click="$emit('click:stage', { picture, index })"
                      format="webp"
                      sizes="sm:380px md:400px lg:640px"
                      quality="80"
                      width="570"
                      height="380"
                    />
                  </a>
                </slot>
              </li>
            </ul>
          </div>
          <div class="glide__arrows control-btn" data-glide-el="controls">
            <button class="glide__arrow glide__arrow--right next-position" data-glide-dir=">" @click="debouncedNextClick" aria-label="Next">
              <nuxt-img src="/global/next-icon.svg" alt="next icon" width="45" height="45" />
            </button>
            <button class="glide__arrow glide__arrow--left prev-position" data-glide-dir="<" @click="debouncedPrevClick" aria-label="Previous">
              <nuxt-img src="/global/prev-icon.svg" alt="previous icon" width="45" height="45" />
            </button>
          </div>
          <div class="logo-tags" v-if="logoTagLabel">
            <nuxt-img
              v-if="logoTagLabel && logoTagLabel === 'Low Working Hours'"
              width="47px"
              height="47px"
              src="/global/low-working.svg"
              alt="Low Working Hours"
            />
            <nuxt-img
              v-if="logoTagLabel && logoTagLabel === 'Fuel Friendly'"
              width="47px"
              height="47px"
              src="/global/fuel_friendly.svg"
              alt="Fuel Friendly"
            />
          </div>
          <span v-if="currentNavTab === 'buy'" class="label">{{ getProductLabel(product) }}</span>
          <span v-else class="label">2018 - {{ getCurrentYear }}</span>
          <div class="control-btn gallery-nav">
            <button @click.stop="openVideoModal()" aria-label="Play" v-if="hasVideo">
              <nuxt-img src="/global/video-icon.svg" alt="gallery video icon" width="46" height="46" />
            </button>
            <button @click.stop="open360Modal()" aria-label="Play360" v-if="product && product['base_url_360']" class="spacebtn">
              <nuxt-img src="/global/360-icon.svg" alt="gallery 360 icon" width="47" height="46" />
            </button>
          </div>
        </div>
      </div>
      <div class="sf-gallery__thumbs" id="galleryThumb">
        <slot name="thumbs" v-bind="{ images, active: activeIndex, go }">
          <span
            v-for="(image, index) in images"
            :key="'img-' + index"
            class="sf-gallery__item"
            :class="{ 'sf-gallery__item--selected': index === activeIndex }"
            :aria-label="'Image ' + index"
            @click="go(index)"
          >
            <nuxt-img
              class="sf-gallery__thumb"
              :src="image.mobile.url"
              :alt="image.alt"
              :loading="index > 3 ? 'lazy': ''"
              :preload="index < 4"
              sizes="sm:136px lg:240px"
              densities="x1 x2"
              width="136"
              height="136"
            />
          </span>
        </slot>
      </div>
    </div>
    <div v-show="glide === null">
      <div class="stage-load">
        <div>
          <SkeletonLoader
            class="stage-loader"
          />
        </div>
        <div class="thumb-load mb-2">
          <SkeletonLoader
            class="skel-load"
          />
          <SkeletonLoader
            class="skel-load"
          />
          <SkeletonLoader
            class="skel-load"
          />
          <SkeletonLoader
            class="skel-load desktop-only"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@nuxtjs/composition-api';
import Glide from '@glidejs/glide';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { eventBus } from "~/almarwan/helpers/EventBus";
import { useUiHelpers } from '~/composables';
import {debounce} from 'lodash-es';

export default {
  name: 'AlmarwanProductGallery',
  components: {
    SkeletonLoader,
  },
  props: {
    currentNavTab: {
      type: String,
      required: true
    },
    logoTagLabel: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: null
    },
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: 100,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    thumbWidth: {
      type: [Number, String],
      default: null,
    },
    thumbHeight: {
      type: [Number, String],
      default: null,
    },
    current: {
      type: Number,
      default: 1,
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          type: "slider",
          autoplay: false,
          rewind: false,
          gap: 0,
          draggable: false
        };
      },
    },
  },
  data() {
    return {
      positionStatic: {},
      eventHover: {},
      loadGlide: false,
      activeIndex: this.current - 1,
      style: "",
      glide: null,
      pictureSelected: this.images[0] || {
        alt: "",
        zoom: "",
        big: {
          url: "",
        },
        desktop: "",
        placeholder: "",
      },
      isZoomStarted: false,
    };
  },
  computed: {
    updatedSliderOptions() {
      return { ...this.sliderOptions, startAt: this.activeIndex, direction: this.$i18n.locale === 'ar' ? 'rtl' : 'ltr' };
    },
  },
  mounted() {
    this.$nextTick(() => {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length < 1) return;
      const glide = new Glide(this.$refs.pg_glide, this.updatedSliderOptions);
      glide.mount();
      this.glide = glide;
    });
  },
  updated() {
    if (this.glide) {
      this.$nextTick(() => {
        this.glide.mount();
      });
    }
  },
  beforeDestroy() {
    if (this.glide) {
      this.glide.destroy();
    }
  },
  created() {
    this.debouncedNextClick = debounce(this.next, 500);
    this.debouncedPrevClick = debounce(this.prev, 500);
  },
  methods: {
    next() {
      if(this.activeIndex + 1 < this.images.length) {
        this.activeIndex ++;
        this.$emit("click", this.activeIndex);
        if (this.glide && this.images[this.activeIndex]) {
          this.glide.go(`=${this.activeIndex}`);
        }
        const scrollContainer = document.querySelector('#galleryThumb');
        if (scrollContainer) {
          scrollContainer.scrollTo({
            left: scrollContainer.scrollLeft + 136,
            behavior: 'smooth'
          });
        }
      }
    },
    prev() {
      if(this.activeIndex - 1 >= 0) {
        this.activeIndex--;
        this.$emit("click", this.activeIndex);
        if (this.glide && this.images[this.activeIndex]) {
          this.glide.go(`=${this.activeIndex}`);
        }
        const scrollContainer = document.querySelector('#galleryThumb');
        if (scrollContainer) {
          scrollContainer.scrollTo({
            left: scrollContainer.scrollLeft - 136,
            behavior: 'smooth'
          });
        }
      }
    },
    go(index) {
      if (this.images[index]) {
        this.pictureSelected = this.images[index];
        if (!this.glide) return;
        this.activeIndex = index;
        /**
         * Event for current image change (`v-model`)
         * @type {Event}
         */
        this.$emit("click", index + 1);
        if (this.glide) {
          this.glide.go(`=${index}`);
        }
      } else {
        this.activeIndex = 0
      }
    },
  },
  setup(props) {
    const { getCurrentYear } = useUiHelpers()
    const hasVideo = ref(false)
    const getProductLabel = (product) => {
      if (!product.condition) {
        return null
      }

      if (product.condition === '621') {
        return 'Used'
      } else if (product.condition === '620') {
        return 'Like New'
      } else if (product.condition === '619') {
        return 'New'
      }
    }

    const open360Modal = () => {
      eventBus.$emit('openModal360', true)
    }
    const openVideoModal = () => {
      eventBus.$emit('openModalVideo', true)
    }
    const getProductGallery = () => {
      hasVideo.value = props.product.media_gallery?.some((element) => element.video_content && !element.disabled);
    };

    onMounted(async () => {
      getProductGallery()
    })
    return {
      open360Modal,
      openVideoModal,
      getProductLabel,
      getCurrentYear,
      hasVideo
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/molecules/SfGallery.scss";
.glide__slide {
  @include for-mobile {
    min-height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    @media screen and (max-width: 1024px) {
      max-height: 240px;
    }
    @include for-mobile {
      max-height: 100%;
    }
  }
}
.prod-gallery {
  max-height: 380px;
  @include for-mobile {
    min-height: 100%;
  }
  img {
    max-height: 380px;
    @media screen and (max-width: 1024px) {
      max-height: 240px;
    }
    @include for-mobile {
      max-height: 220px;
    }
  }
}
.control-btn {
    position: absolute;
    bottom: 15px;
    left: 10px;
    display: flex;
    column-gap: 5px;
    flex-direction: row-reverse;
}
.prev-position {
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 2;
  @include for-mobile {
    bottom: 12%;
  }
}
.next-position {
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 1;
  @include for-mobile {
    bottom: 12%;
    left: 15%;
  }
}
.glide__arrow--disabled {
  opacity: 0.35;
}
.sf-gallery {
  display: flex;
  --gallery-thumbs-flex-direction: column;
  @include for-desktop {
    flex-direction: column-reverse !important;
  }
}
.stage-loader {
  margin: 0 0 10px 0 !important;
  @media screen and (max-width: 1024px) {
    max-height: 240px !important;
  }
  @media screen and (max-width: 768px) {
    max-height: 220px !important;
  }
}
</style>
