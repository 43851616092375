


































































































































































































































































































































import {
  computed,
  defineComponent,
  PropType,
  toRef,
  ref,
  onMounted,
  useRouter,
  nextTick, watch, useRoute,
} from '@nuxtjs/composition-api';
import { getShortDescription, getPrice, getReservationFee } from '../../../getters/productGetters';
import { eventBus } from '../../../../../../almarwan/helpers/EventBus';
import { useUser } from '../../../../../customer/composables/useUser';
import { Product } from '../../../../product/types';
import { useUiHelpers, useUiState, useUiNotification, useMagentoConfiguration } from '../../../../../../composables';
import { useAddToCart } from '../../../../../../helpers/cart/addToCart';
import { useCart } from '../../../../../checkout/composables/useCart';
import { useProductGallery } from '../../../composables/useProductGallery';
import ProductGallery from "../../ProductGallery.vue";
import AddToCart from "../../../../../../components/CTA/AddToCart.vue";
import AlmarwanProductGallery from '../../../../../../components/ProductGallery.vue';
import SkeletonLoader from '../../../../../../components/SkeletonLoader/index.vue';
import LazyHydrate from 'vue-lazy-hydration';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    SkeletonLoader,
    AddToCart,
    ProductGallery,
    AlmarwanProductGallery,
    LazyHydrate,
    FinanceOnProductDetail: () => import('../../Financing/FinanceOnProductDetail.vue'),
    GetInTouchLabel: () => import('../../../../../../almarwan/components/GetInTouchLabel.vue'),
    ProductDetailsTabs: () => import('../../../../../../components/ProductDetailsTabs.vue'),
    ExpressInterest: () => import('../../../../../../components/CTA/ExpressInterest.vue'),
    WishlistGuest: () => import('../../../../../../components/General/WishlistGuest.vue'),
    InstallationInfo: () => import('../../../../../../components/InstallationInfo.vue'),
    SocialLabel: () => import('../../../../../../almarwan/components/SocialLabel.vue'),
    AvailableBrands: () => import('../../../../../../components/AvailableBrands.vue'),
    OperatorSkills: () => import('../../../../../../components/OperatorSkills.vue'),
    WarrantyInfo: () => import('../../../../../../components/WarrantyInfo.vue'),
    MakeOffer: () => import('../../../../../../components/CTA/MakeOffer.vue'),
    NotifyMe: () => import('../../../../../../components/CTA/NotifyMe.vue'),
  },
  beforeDestroy() {
    localStorage.removeItem('userClickedDownloadSpec');
    eventBus.$off('successOfferId')
  },
  watch: {
    amount(newValue) {
      const result = newValue?.replace(/\D/g, "")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      nextTick(() => this.amount = result);
    }
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    rentOrBuyVal: {
      type: String,
      default: '',
    },
    logoTagLabel: {
      type: String,
      default: '',
    },
    availableForProp: {
      type: String,
      required: true
    },
  },
  setup(props) {
    // @ts-ignore: Unreachable code error
    const product = toRef(props, 'product');
    const expressInterestLoading = ref(false);
    const sriv360View = ref(false);
    const loading = ref(false);
    const currentNavTab = ref('');
    const labelClass = ref('label--green');
    const router = useRouter();
    const route = useRoute();
    const {
      toggleLoginModal,
      setProdInfo,
      toggleMakeOfferModal,
      setExpressInterest,
      setMakeOffer,
      setOfferAmount,
      setNotifyMe,
    } = useUiState();
    const {
      user,
      isAuthenticated,
      productAlertNotifyInStock,
      createMakeOffer,
      recentViewedProducts
    } = useUser();
    const { send: sendNotification } = useUiNotification();
    const { cart, addItem } = useCart();
    const { addItemToCart } = useAddToCart();
    const { numberWithCommas, strippedHtml, getFullUrl } = useUiHelpers()
    const {
      selectedCurrency
    } = useMagentoConfiguration();
    const makeofferLoading = ref(false);
    const notifyLoading = ref(false);
    const qty = ref(1);
    const selectedWarranty = ref(null);
    const installationStatus = ref(null);
    const toggleChecked = ref(false);
    const amount = ref('');
    const afterAdd = ref(false);
    const offerPlaced = ref(false);
    const offerList = ref([]);
    const description = ref('');
    const isShowMoreActive = ref(true);
    const showThumbnailSlider = ref(false);
    const isFinanceActiveBox = ref(false);
    const isCartActiveBox = ref(true);
    const techSpec = ref([
      {
        value: 'sku',
        label: 'Reference Number',
      },
      {
        value: 'hours',
        label: 'Working Hours',
      },
      {
        value: 'filter_milage',
        label: 'Milage',
      },
      {
        value: 'equipment_attachment_serial_no',
        label: 'Machine Serial',
      },
    ])
    const productShortDescription = getShortDescription(product.value);
    // TODO have a variable for getPrice instead of calling it everywhere
    // const productRegularPrice = computed(() => getPrice(product.value).regular);
    const financeLinkClicked = ref(false);
    const availableFor = computed(() => product.value?.listings); // '763'=buy, '764'=rent, '763,764'=both
    const availableBrandsIds = ref([
      {
        id: '646',
        src: '/global/availableBrands/Caterpillar_Logo_menu.jpg',
        alt: 'Caterpillar'
      },
      {
        id: '647',
        src: '/global/availableBrands/Komatsu_Logo_menu.jpg',
        alt: 'Komatsu'
      }
      ,
      {
        id: '658',
        src: '/global/availableBrands/Volvo_Logo_menu.jpg',
        alt: 'Volvo'
      },
      {
        id: '649',
        src: '/global/availableBrands/Hitachi_Logo_menu.jpg',
        alt: 'Hitachi'
      },
      {
        id: '648',
        src: '/global/availableBrands/JCBr_Logo_menu.jpg',
        alt: 'JCB'
      },
      {
        id: '652',
        src: '/global/availableBrands/Bomagr_Logo_menu.jpg',
        alt: 'Bomag'
      },
      {
        id: '676',
        src: '/global/availableBrands/Kalmar_Logo_menu.jpg',
        alt: 'Kalmar'
      },
      {
        id: '629',
        src: '/global/availableBrands/Kobelco_Logo_menu.jpg',
        alt: 'Kobelco'
      },
      {
        id: '697',
        src: '/global/availableBrands/Powerscreen_Logo_menu.jpg',
        alt: 'Powerscreen'
      },
      {
        id: '645',
        src: '/global/availableBrands/Sakai_Logo_menu.jpg',
        alt: 'Sakai'
      },
      {
        id: '655',
        src: '/global/availableBrands/wirtgen_Logo_menu.jpg',
        alt: 'Wirtgen'
      },
      {
        id: '703',
        src: '/global/availableBrands/Bobcat_Logo_menu.jpg',
        alt: 'Bobcat'
      },
      {
        id: '663',
        src: '/global/availableBrands/ammann-rental.png',
        alt: 'Ammann'
      },
      {
        id: '693',
        src: '/global/availableBrands/benford-rental.png',
        alt: 'Benford'
      },
      {
        id: '642',
        src: '/global/availableBrands/DYNAPAC.png',
        alt: 'DYNAPAC'
      },
      {
        id: '669',
        src: '/global/availableBrands/hamm-renta.png',
        alt: 'Hamm'
      },
      {
        id: '679',
        src: '/global/availableBrands/kato-rental.png',
        alt: 'Kato'
      },
      {
        id: '700',
        src: '/global/availableBrands/konecranes-rental.png',
        alt: 'konecranes'
      },
      {
        id: '660',
        src: '/global/availableBrands/mackoskey-rental.png',
        alt: 'mackoskey'
      },
      {
        id: '661',
        src: '/global/availableBrands/MANITOU-LOGO.png',
        alt: 'MANITOU'
      },
      {
        id: '662',
        src: '/global/availableBrands/sandvik-rental.png',
        alt: 'sandvik'
      },
      {
        id: '650',
        src: '/global/availableBrands/SANY-RENTAL.png',
        alt: 'SANY'
      },
      {
        id: '667',
        src: '/global/availableBrands/Tadano-rental.png',
        alt: 'Tadano'
      },
      {
        id: '664',
        src: '/global/availableBrands/wacker-neuson-logo.png',
        alt: 'neuson'
      },
      {
        id: '665',
        src: '/global/availableBrands/wirtgen-rental.png',
        alt: 'wirtgen'
      },
    ])
    const { productGallery, imageSizes } = useProductGallery(product);
    const productAlertNotifyInStockCTA = async (product) => {
      if (isAuthenticated.value) {
        notifyLoading.value = true;
        await productAlertNotifyInStock(product.id);
        notifyLoading.value = false;
      } else {
        let pArr = [
          {
            'name': product.name,
            'sku': product.sku,
            'id': product.id
          }
        ];
        setProdInfo(pArr);
        setNotifyMe();
        toggleMakeOfferModal()
      }
    };
    const postInterest = async (prod, tab) => {
      let prodCatObj = prod.categories.find(o => o.level == 2);
      let pArr = [
        {
          'id': prodCatObj.id,
          'name': prod.name,
          'sku': prod.sku,
          'type': tab
        }
      ];
      setProdInfo(pArr);
      toggleMakeOfferModal();
      setExpressInterest();
    }
    const makeNewOffer = async (prod) => {
      if (amount.value) {
        setOfferAmount(amount.value);
        amount.value = amount?.value.replace(/\,/g, '');
      } else {
        setOfferAmount('');
      }
      if (isAuthenticated.value) {
        if (parseInt(amount.value) > 0) {
          makeofferLoading.value = true;
          const offerNew = await createMakeOffer(prod.sku, amount.value);
          makeofferLoading.value = false;
          if (offerNew[0]['makeOffer'] == 'success') {
            const customOption = {reserve_makeoffer: offerNew[0]['id']};
            const selectedOptions = [];
            if (selectedWarranty.value !== null) {
              selectedOptions.push(selectedWarranty.value);
            }
            if (installationStatus.value !== null) {
              selectedOptions.push(installationStatus.value);
            }
            await addItemToCart({
              product: prod,
              quantity: qty.value,
              custom_option: customOption,
              selected_options: selectedOptions
            });
          }
        } else {
          sendNotification({
            id: Symbol('product_removed'),
            message: `Please enter a valid amount.`,
            type: 'danger',
            icon: 'error',
            persist: false,
            title: 'Invalid amount!',
          });
        }
      } else {
        let pArr = [
          {
            'name': prod.name,
            'sku': prod.sku,
          }
        ];
        setProdInfo(pArr);
        toggleMakeOfferModal();
        setMakeOffer();
      }
      await getMakeOffers()
      checkIfOfferMade()
    };
    const loadFinancePage = (sku) => {
      localStorage.setItem('prodUrl', router.currentRoute.fullPath)
      financeLinkClicked.value = true;
      if (!isAuthenticated.value) {
        toggleLoginModal();
        return
      }
      router.push(`/credit-application?sku=${sku}`);
    }

    watch(() => user.value, () => {
      setTimeout(() => {
        if (financeLinkClicked.value) {
          router.push('/credit-application');
        }
      }, 3100);

    });

    const addProductToRecentViewedArray = () => {
      // @ts-ignore: Unreachable code error
      if (isAuthenticated.value && product.value && product.value?.id !== null) {
        // @ts-ignore: Unreachable code error
        recentViewedProducts([product.value.id])
      }
    }
    const addProductToRecentViewedGuestArray = () => {
      if (!isAuthenticated.value && product.value) {
        let guestRecentViewList = []
        if (localStorage.getItem("guestRecentViewedArray")) {
          guestRecentViewList = JSON.parse(localStorage.getItem("guestRecentViewedArray"));
        }
        // @ts-ignore: Unreachable code error
        const checkIfAlreadyExist = guestRecentViewList.find((prod) => prod.id === product.value.id)
        if (!checkIfAlreadyExist) guestRecentViewList.push(product.value)
        if (guestRecentViewList.length > 10) {
          guestRecentViewList.shift();
        }
        localStorage.setItem("guestRecentViewedArray", JSON.stringify(guestRecentViewList))
      }
    }

    const setTagClass = (value) => {
      let array = ['', 'label--purple', 'label--red', 'label--purple', 'label--red', 'label--green', 'label--green'];
      labelClass.value = array[value];
    }

    const getTagLabel = (prod) => {
      if (!prod.hasOwnProperty('available_for_tags') || !prod.available_for_tags.length) {
        return '';
      }
      const firstTag = prod.available_for_tags[0];
      const secondTag = prod.available_for_tags[1];

      switch (prod.listings) {
        case '763,764':
          if (currentNavTab.value === 'rent') {
            setTagClass(secondTag?.value);
            return secondTag?.label;
          } else {
            setTagClass(firstTag?.value);
            return firstTag?.label;
          }
        case '763':
        case '764':
          setTagClass(firstTag?.value);
          return firstTag?.label;
        default:
          return '';
      }
    }

    onMounted(async () => {
      Fancybox.bind('[data-fancybox="gallery"]', {
        contentClick: "iterateZoom",
        Images: {
          protected: true,
          Panzoom: {
            maxScale: 2,
          },
        },
      });
      if (props.availableForProp) {
        if (props.availableForProp === '763') {
          localStorage.setItem('rentOrBuy', 'buy')
          currentNavTab.value = 'buy'
        }
        if (props.availableForProp === '764') {
          localStorage.setItem('rentOrBuy', 'rent')
          currentNavTab.value = 'rent'
        }
      }
      await getMakeOffers()
      await checkIfOfferMade()
      eventBus.$on('successOfferId', (val) => {
        if (product.value['sku'] == val.prodSku) {
          // @ts-ignore: Unreachable code error
          addItemToCart({
            product: product.value,
            quantity: qty.value,
            custom_option: {reserve_makeoffer: val.offferid}
          })
        }
      });
      eventBus.$on('makeOfferFailed', (val) => {
        sendNotification({
          id: Symbol('product_removed'),
          message: val,
          type: 'warning',
          icon: 'error',
          persist: false,
          title: 'Invalid amount!',
        });
      });
      eventBus.$on('makeOfferSuccess', (val) => {
        sendNotification({
          id: Symbol(''),
          message: 'Thank You For Your Interest.We are happy to contact you regarding all the details.',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Make Offer',
        });
      })
      if (isAuthenticated.value) {
        setTimeout(() => {
          addProductToRecentViewedArray()
        }, 1500)
      }
      if (!isAuthenticated.value) {
        setTimeout(() => {
          addProductToRecentViewedGuestArray()
        }, 2500)
      }
      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        if (props.product?.options?.[0]?.value?.[0]?.uid) {
          // @ts-ignore: Unreachable code error
          selectedWarranty.value = props.product?.options?.[0]?.value[0]?.uid
        }
        // @ts-ignore
        let prod_img = props.product?.image?.url;
        document.querySelectorAll('meta[property="og:image"]')?.[0]?.setAttribute('content', prod_img)
        document.querySelectorAll('meta[property="twitter:image"]')?.[0]?.setAttribute('content', prod_img)
      }, 300)
      showThumbnailSlider.value = true;
      if (product.value && product.value.sku && alreadyInCart(product.value.sku)) {
        setTimeout(() => {
          afterAdd.value = true;
        }, 1000);
      }
      if (window) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    });
    const handleFinanceIsActiveBox = () => {
      isFinanceActiveBox.value = true;
      isCartActiveBox.value = false ;
    };
    const activeBox = () => {
      isCartActiveBox.value = true;
      isFinanceActiveBox.value = false;
    };
    const warrantyEnabled = (options) => {
      let stat = false
      options?.forEach(element => {
        if (element.title === 'Warranty') {
          stat = true
          return
        }
      });
      return stat;
    }
    const installationEnabled = (options) => {
      let stat = false;
      options?.forEach(element => {
        if (element.title === 'Installation') {
          stat = true
          setInstallationStatus(options)
          return
        }
      });
      return stat;
    }
    const setSelectedWarranty = (wItem) => {
      selectedWarranty.value = wItem.uid
    }
    const setInstallationStatus = (options) => {
      let newStat = 'No'
      if (toggleChecked.value) {
        newStat = 'Yes';
      }
      const instOptions = options.filter(item => item.title === "Installation").map(vlas => vlas.value);
      if (instOptions.length > 0) {
        const selUid = instOptions[0].filter(item => item.title === newStat);
        installationStatus.value = selUid[0].uid;
      }
    }
    const alreadyInCart = (sku) => cart.value?.items?.some((productItem) => productItem?.product?.sku === sku) ?? false;

    const { makeOffers } = useUser();
    const getMakeOffers = async () => {
      if (isAuthenticated.value) {
        const res = await makeOffers(50, 1);
        if (res) offerList.value = res?.items
      } else {
        offerList.value = []
      }
    };
    const checkIfOfferMade = () => {
      if (offerList.value && offerList.value.length && product.value['sku']) {
        const isOfferValid = (offer) => offer.product.sku === product.value['sku'] && !['withdrawn', 'full payment accepted'].includes(offer.status.toLowerCase());
        offerPlaced.value = offerList.value.some(isOfferValid);
      }
    };

    const productOptions = computed(() => product.value?.options)
    const getBaseRoute = computed(()=> route.value.path)

    const truncatedDescription = computed(() => {
      const strhtml = strippedHtml(productShortDescription, 200)
      if (!strhtml.trim()) {
        return '';
      }
      const wordsArray = strhtml.trim().split(/\s+/);
      return wordsArray.slice(0, 30).join(' ');
    });
    const canMakeOffer = (prod) => prod.hasOwnProperty('can_makeoffer') && prod.can_makeoffer && prod.reserve_fee > 0;
    const canInquiry = (prod) => !!(prod.hasOwnProperty('can_inquiry') && prod.can_inquiry);

    const addProductToCart = (prod) => {
      const selectedOptions = [];
      if (selectedWarranty.value !== null) {
        selectedOptions.push(selectedWarranty.value);
      }
      if (installationStatus.value !== null) {
        selectedOptions.push(installationStatus.value);
      }
      if (selectedOptions.length) {
        addItemToCart({product: prod, quantity: qty.value, selected_options: selectedOptions});
      } else {
        addItemToCart({product: prod, quantity: qty.value, custom_option: { reserve_order: true } });
      }
      setTimeout(() => {
        afterAdd.value = true;
      }, 5000);
    }
    const readMore = () => {
      isShowMoreActive.value = !isShowMoreActive.value
    }

    return {
      productAlertNotifyInStockCTA,
      productAlertNotifyInStock,
      handleFinanceIsActiveBox,
      setInstallationStatus,
      truncatedDescription,
      toggleMakeOfferModal,
      setSelectedWarranty,
      installationEnabled,
      getShortDescription,
      setExpressInterest,
      toggleLoginModal,
      numberWithCommas,
      addProductToCart,
      loadFinancePage,
      warrantyEnabled,
      createMakeOffer,
      setOfferAmount,
      alreadyInCart,
      addItemToCart,
      postInterest,
      makeNewOffer,
      canMakeOffer,
      setMakeOffer,
      getTagLabel,
      setProdInfo,
      canInquiry,
      activeBox,
      readMore,
      addItem,
      productShortDescription,
      expressInterestLoading,
      //productRegularPrice,
      showThumbnailSlider,
      installationStatus,
      availableBrandsIds,
      isFinanceActiveBox,
      selectedWarranty,
      isShowMoreActive,
      makeofferLoading,
      isAuthenticated,
      isCartActiveBox,
      productGallery,
      productOptions,
      notifyLoading,
      toggleChecked,
      currentNavTab,
      getBaseRoute,
      availableFor,
      offerPlaced,
      description,
      sriv360View,
      labelClass,
      imageSizes,
      techSpec,
      afterAdd,
      loading,
      amount,
      cart,
      qty,
      getFullUrl,
      selectedCurrency,
      getPrice,
      getReservationFee
    };
  }
});
