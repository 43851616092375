


























































































import { defineComponent, onMounted, onUnmounted, ref } from "@nuxtjs/composition-api";
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import { eventBus } from "~/almarwan/helpers/EventBus";
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default defineComponent({
  name: "ProductGallery",
  directives: {
    clickOutside
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    logoTagLabel: {
      type: String,
      default: '',
    },
    currentNavTab: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const hasVideo = ref(false)
    const sriv360View = ref(false)
    const loadIframe = ref(false)
    const isModalActive = ref(false)
    const slideIndex = ref(1)
    const videoSlideIndex = ref(1)
    const lightBoxCounter = ref('')
    const mainPreview = ref(null)
    const slides = ref(null)
    const showThumbnailSlider = ref(false)
    const documentCheck = typeof document && typeof window
    const mainSlideSettings = ref({
      focusOnSelect: true,
      arrows: true,
      infinite: true,
    })
    const syncSliders = (currentPosition, nextPosition) => {
      // @ts-ignore: Unreachable code error
      mainPreview.value.goTo(nextPosition)
      // @ts-ignore: Unreachable code error
      slides.value.goTo(nextPosition)
    }
    const getProductGallery = (galleryItems) => {
      let gallery = [];
      galleryItems.forEach((element, i) => {
        if (!element.disabled) {
          if (element.__typename == 'ProductImage') {
            gallery.push(element)
          }
          if (element.__typename == 'ProductVideo') {
            hasVideo.value = true;
          }
        }
      });
      // function(a, b) {
      //   return parseFloat(a.position) - parseFloat(b.position);
      // }
      return gallery.sort();
    }
    const openVideoModal = () => {
      sriv360View.value = false
      loadIframe.value = true
      setTimeout(() => {
        document.getElementById("myVideoModal").style.display = "flex";
        // closeModal()
      }, 200)
    }
    const openVideo = () => {
      sriv360View.value = false
      setTimeout(() => {
        // @ts-ignore
        props.product['media_gallery'].forEach((element, index) => {
          if (!element.disabled) {
            if (element.__typename == 'ProductVideo') {
              currentVideoSlide(1)
            }
          }
        });
      }, 200)
    }
    const currentVideoSlide = (n) => {
      sriv360View.value = false
      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        showVideoSlides(slideIndex.value = n);
      }, 200)
    }
    const showVideoSlides = (n) => {
      sriv360View.value = false
      let i;
      let slides = document.getElementsByClassName("myVideoSlides");
      let slidesLength = 0;
      // @ts-ignore
      props.product['media_gallery'].forEach((element, index) => {
        if (!element.disabled && element.__typename == 'ProductVideo') {
          slidesLength = slidesLength + 1;
        }
      });
      if (n > slidesLength) {
        videoSlideIndex.value = 1;
        n = 1;
      }
      if (n < 1) {
        videoSlideIndex.value = slidesLength
        n = slidesLength
      }
     lightBoxCounter.value = n + '/' + slidesLength;
      for (i = 0; i < slidesLength; i++) {
        // @ts-ignore: Unreachable code error
        slides[i].style.display = "none";
      }
      // @ts-ignore: Unreachable code error
      slides[parseInt(videoSlideIndex.value) - 1].style.display = "block";
    }
    const open360Modal = () => {
      document.getElementById("my360Modal").style.display = "flex";
      setTimeout(() => {
        open360View()
      }, 200)
    }
    const open360View = () => {
      isModalActive.value = false;
      const script = document.createElement('script')
      script.src = 'https://scripts.sirv.com/sirvjs/v3/sirv.js'
      script.defer = true
      document.head.appendChild(script)
      setTimeout(() => {
        sriv360View.value = true
        // @ts-ignore: Unreachable code error
        Sirv.start();
        closeVideoModal()
        // closeModal()
      }, 500)
    }
    const openModal = () => {
      document.getElementById("myModal").style.display = "flex";
    }
    const currentSlide = (n) => {
      sriv360View.value = false
      // @ts-ignore: Unreachable code error
      showSlides(slideIndex.value = n);
    }
    const showSlides = (n) => {
      sriv360View.value = false
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let slidesLength = 0;
      // @ts-ignore
      props.product['media_gallery'].forEach((element, index) => {
        if (!element.disabled && element.__typename != 'ProductVideo') {
          // @ts-ignore: Unreachable code error
          slidesLength = slidesLength + 1;
        }
      });
      if (n > slidesLength) {
        slideIndex.value = 1;
        n = 1;
      }
      if (n < 1) {
        slideIndex.value = slidesLength
        n = slidesLength
      }
      lightBoxCounter.value = n + '/' + slidesLength;
      for (i = 0; i < slidesLength; i++) {
        // @ts-ignore: Unreachable code error
        slides[i].style.display = "none";
      }
      // @ts-ignore: Unreachable code error
      slides[parseInt(slideIndex.value) - 1].style.display = "block";
    }
    const getProductLabel = (product) => {
      if (!product.condition) {
        return null
      }

      if (product.condition === '621') {
        return 'Used'
      } else if (product.condition === '620') {
        return 'Like New'
      } else if (product.condition === '619') {
        return 'New'
      }
    }
    const getImgSlideCount = () => {
      let slidesLength = 0;
      // @ts-ignore
      props.product['media_gallery']?.forEach((element, index) => {
        if (!element.disabled && element.__typename !== 'ProductVideo') {
          // @ts-ignore: Unreachable code error
          slidesLength = slidesLength + 1;
        }
      });
      return slidesLength;
    }
    // const closeModal = () => {
    //   document.getElementById("myModal").style.display = "none";
    // }
    const close360Modal = () => {
      document.getElementById("my360Modal").style.display = "none";
      sriv360View.value = false
    }
    const closeVideoModal = () => {
      // @ts-ignore: Unreachable code error
      toggleVideo('hide');
      document.getElementById("myVideoModal").style.display = "none";
    }
    const toggleVideo = (state) => {
      let div = document.getElementById("popupVid");
      if (div) {
        let iframe = div?.getElementsByTagName("iframe")[0].contentWindow;
        div.style.display = state == 'hide' ? 'none' : '';
        let func = state == 'hide' ? 'pauseVideo' : 'playVideo';
        iframe.postMessage('{"event":"command","func":"' + func + '","args":""}', '*');
      }
    }
    const plusSlides = (n) => {
      sriv360View.value = false
      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        showSlides(slideIndex.value += n);
      }, 200)
    }
    const getVideoUrl = (video_content) => {
      if (video_content.video_url.includes('youtube')) {
        let link = 'https://www.youtube.com/embed/' + video_content.video_url.split('?v=')[1];
        if (link.indexOf("?") > -1) {
          return link + '&enablejsapi=1';
        } else {
          return link + '?enablejsapi=1';
        }
      }
      if (video_content.video_url.includes('youtu.be')) {
        let link = 'https://www.youtube.com/embed/' + video_content.video_url.split('youtu.be/')[1];
        if (link.indexOf("?") > -1) {
          return link + '&enablejsapi=1';
        } else {
          return link + '?enablejsapi=1';
        }
      }

      return 'https://player.vimeo.com/video/' + video_content.video_url.split('vimeo.com/')[1]
    }

    const onKeyDown = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // closeModal();
        closeVideoModal()
        close360Modal()
      }
      if (event.key === 'ArrowRight' || event.keyCode === 39) {
        plusSlides(1)
      }
      if (event.key === 'ArrowLeft' || event.keyCode === 37) {
        plusSlides(-1)
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', onKeyDown);
      setTimeout(() => {
        showThumbnailSlider.value = true;
      }, 1000);

      eventBus.$on('openModal360', (val) => {
        open360Modal()
      });
      eventBus.$on('openModalVideo', (val) => {
        openVideoModal()
        openVideo()
      });
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', onKeyDown);
    })

    return {
      currentVideoSlide,
      getProductGallery,
      getImgSlideCount,
      showVideoSlides,
      closeVideoModal,
      getProductLabel,
      openVideoModal,
      close360Modal,
      open360Modal,
      currentSlide,
      getVideoUrl,
      toggleVideo,
      open360View,
      syncSliders,
      // closeModal,
      plusSlides,
      onKeyDown,
      openModal,
      openVideo,
      mainSlideSettings,
      lightBoxCounter,
      isModalActive,
      documentCheck,
      sriv360View,
      mainPreview,
      loadIframe,
      hasVideo,
      slides,
      showThumbnailSlider
    }
  }
})
